import React, { useState } from "react";
import LeftSideNavigationEnglish from "../../component/leftSideNavigationEnglish";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { MAEnglishCourseDistribution } from "../../../../static";

const ProgramsMAEnglishDepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of English" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEnglish />
        <div className="rightSideContent m-4">
          <p className="heading4 text-center">
            M.A. in ELL (English Language & Literature)
          </p>
          <p className="allText">
            M.A. in ELL program consists of 2 semesters and 38 credit hours
            distributed over 12 courses in a span of one year. A viva voce exam
            of 2 credit hours will be held after the completion of all the
            written courses. The first semester comprises of 6 courses of 3
            credit hours each including a course on Research Methodology. The
            second semester comprises of 6 courses of 3 credit hours each along
            with a Dissertation or Internship. The second semester evaluation
            includes a viva of 2 credit hours.
          </p>
          <p className="heading5">Admission Requirement</p>
          <p className="allText">
            For admission into M.A. in ELL (English Language and Literature) a
            candidate must have a 4-year B.A. (Hons.) degree in English or must
            have passed M.A. (Preliminary) in English.
          </p>

          <p className="heading5">Degree Requirements</p>
          <p className="allText">
            To qualify for the M.A. in ELL degree, a student has to pass all the
            required courses individually, earn a minimum Cumulative Grade Point
            Average (CGPA) of 2.50 on completion of 38 credit hours and maintain
            a record of good conduct.
          </p>
          <p className="heading5">
            <a href={MAEnglishCourseDistribution}>
              Download Course Distribution
            </a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEnglish />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default ProgramsMAEnglishDepartment;
